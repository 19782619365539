/*  ==========================================================================
	## MAIN NAVIGATION
	======================================================================= */

.mainNav {
	$base: #{&};

	display: flex;
	align-items: center;
	justify-content: flex-end;

	//Custom number due to breaking menu.
	@include media-max($menubreak) {
		display: none;
	}

	&__list {
		margin: 0 0.75rem 0 0;
		padding: 0;
		text-align: left;
		list-style-type: none;
	}

	&__item {
		display: none;

		&.-showInMainMenu {
			display: inline-block;

			&:nth-child(n + 5) {
				display: none;
			}
		}
	}

	&__link {
		@extend %fs-summary;
		display: inline-block;
		margin: 0 0.75rem;
		padding: 0.5rem;
		color: $white;
		font-weight: bold;
		line-height: $reduced-line-height;
		transition: all 250ms ease-in-out;
		clip-path: polygon(0 5%, 100% 0, 100% 100%, 0 95%);

		&:hover,
		&:focus {
			color: $black;
			background-color: $brand-orange;
		}

		&.-isCurrent,
		&.-isSection {
			color: $white;
			background-color: $brand-blue;
			border-bottom-color: $brand-text-alt;
			opacity: 1;
		}
	}

	.subNav__list {
		display: none;
	}
}

.subNav {
	display: flex;
	position: sticky;
	top: -2px;
	z-index: 998;
	align-items: center;
	justify-content: center;
	padding: 0;
	background-color: $brand-pop;
	list-style: none;

	@include media-max($menubreak) {
		display: none;
	}

	&[stuck] {
		padding-top: 0;
		box-shadow: $box-shadow;

		&::before {
			opacity: 1;
			transition: opacity 500ms $transition-timing-function;
		}
		transition: padding 250ms $transition-timing-function,
			background-color 250ms;
	}

	&__item {
		position: relative;
		padding: 0.5rem;
	}

	&__link {
		@extend %fs-body;
		display: block;
		margin: 0.5rem 1rem;
		color: $black;
		border-bottom: 2px solid transparent;
		text-decoration: none;
		font-weight: bold;
		line-height: $reduced-line-height;
		opacity: 1;
		padding: 0.5rem;
		clip-path: polygon(0 5%, 100% 0, 100% 100%, 0 95%);

		&:hover,
		&:focus,
		&.-isCurrent,
		&.-isSection {
			color: $black;
			background-color: $white;
		}

		&:focus {
			outline: 2px dashed $black;
			clip-path: none;
		}
	}

	&__icon {
		position: absolute;
		top: 50%;
		right: 0.25rem;
		transform: translateY(-50%);
		margin-top: -2px;
		color: $black;
	}

	&__subSubNav {
		display: none;
		position: absolute;
		top: 100%;
		left: calc(1rem - 1px);
		z-index: 11;
	}

	&__item:hover > &__subSubNav {
		display: block;
	}
}

.subSubNav {
	list-style: none;
	width: 260px;
	background-color: $white;
	box-shadow: $box-shadow;
	border-left: solid 1px $grey;
	border-right: solid 1px $grey;

	&__item {
		border-bottom: solid 1px $grey;

		&:first-child {
			border-top: solid 1px $grey;
		}
	}

	&__link {
		@extend %fs-body;
		display: block;
		padding: 0.75rem 0.5rem;
		color: $black;
		background-color: $white;
		text-decoration: none;
		font-weight: bold;
		line-height: $reduced-line-height;
		opacity: 1;
		transition: background-color $base-duration $base-timing,
			color $base-duration $base-timing;

		&:hover,
		&:focus {
			background-color: $brand-dark;
			color: $black;
		}

		&.-isCurrent,
		&.-isSection {
			background-color: $brand-light;
			color: $black;
		}
	}
}

.headerPopup {
	position: relative;
	z-index: 4;
	width: 44px;
	height: 44px;
	margin-top: -0.5rem;
	padding: 0.3rem;

	&:hover {
		.headerPopup__hamburger,
		.headerPopup__searchIcon {
			background-color: $brand-orange;
		}
	}

	button {
		-webkit-appearance: none;
	}

	&__searchIcon {
		z-index: 101;
		position: absolute;
		right: 4px;
		margin-top: 28px;
		padding: 0;
		width: 22px;
		height: 14px;
		background-color: $black;
		border: none;
		text-align: center;
		line-height: 14px;
		transition: background-color 250ms $transition-timing-function,
			opacity 200ms;
		cursor: pointer;

		&:hover,
		&:focus {
			color: $black;
		}

		svg {
			color: $white;
			width: 12px;
			height: 12px;
		}
	}

	&__hamburger {
		position: absolute;
		right: 0;
		width: 44px;
		height: 44px;
		transform: none;
		transition: background-color 250ms $transition-timing-function,
			opacity 200ms $transition-timing-function,
			250ms transform 200ms $transition-timing-function;
		opacity: 1;
		z-index: 100;
		background: none;
		border: none;
		cursor: pointer;
		transform-origin: 20% 50%;
		will-change: background-color, transform, opacity;

		div {
			position: absolute;
			left: 8px;
			width: 24px;
			height: 2px;
			transition: 500ms background $transition-timing-function,
				500ms margin-top $transition-timing-function,
				500ms margin-bottom $transition-timing-function,
				500ms transform $transition-timing-function 83ms;
			background-color: $white;
			transform-origin: 50% 50%;
		}

		div::before,
		div::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transform: scaleX(0);
			transform-origin: 0 0;
		}

		div::before {
			transform: none;
			transition: 300ms opacity 300ms $transition-timing-function;
		}

		#LineOne {
			top: 12px;

			&::after {
				transition: 670ms transform $transition-timing-function 150ms,
					200ms opacity;
			}
		}

		#LineTwo {
			bottom: 22px;

			&::after {
				transition: 670ms transform $transition-timing-function 233ms,
					200ms opacity;
			}
		}

		#LineThree {
			top: 28px;
			transition: opacity 200ms;
		}
	}

	&.-isOpen {
		.headerPopup__hamburger #LineOne {
			background: $white;
			transform: rotate(45deg);
			margin-top: 3px;

			&::after {
				opacity: 0;
			}
		}

		.headerPopup__hamburger #LineTwo {
			background: $white;
			transform: rotate(-45deg);
			margin-bottom: 3px;

			&::after {
				opacity: 0;
			}
		}

		.headerPopup__searchIcon,
		.headerPopup__hamburger #LineThree {
			opacity: 0;
		}

		.headerPopup__popup {
			visibility: inherit;
			opacity: 1 !important;
			transform: translateY(-10px);
			transition: opacity 750ms $transition-timing-function,
				transform 750ms $transition-timing-function,
				visibility 750ms step-start;
		}

		.headerPopupMenu__item,
		.headerPopupSearch__title,
		.headerPopupSearch__form {
			opacity: 1;
			transform: none;
		}
		.headerPopupSearch__form {
			opacity: 1;
		}
	}
}

.headerPopup__popup {
	position: absolute;
	top: 0;
	right: 0;
	width: 420px;
	padding: 2rem;
	transform: translateY(20px);
	transition: opacity 750ms $transition-timing-function,
		transform 750ms $transition-timing-function, visibility 750ms step-end;
	background-color: $brand-pop;
	box-shadow: $box-shadow;
	z-index: 1;
	opacity: 0;
	visibility: hidden;

	@include media-max($medium) {
		width: calc(100vw - 2rem);
		padding: 1rem;
	}
}

.headerPopupMenu {
	margin: 0;
	padding: 0;
	list-style: none;

	&__item {
		padding: 0.325rem 0;
		transform: translateY(-30px);
		opacity: 0;

		&:nth-child(1) {
			transition: 670ms opacity $transition-timing-function 33ms,
				670ms transform $transition-timing-function 33ms;
		}
		&:nth-child(2) {
			transition: 670ms opacity $transition-timing-function 83ms,
				670ms transform $transition-timing-function 83ms;
		}
		&:nth-child(3) {
			transition: 670ms opacity $transition-timing-function 133ms,
				670ms transform $transition-timing-function 133ms;
		}
		&:nth-child(4) {
			transition: 670ms opacity $transition-timing-function 183ms,
				670ms transform $transition-timing-function 183ms;
		}
		&:nth-child(5) {
			transition: 670ms opacity $transition-timing-function 233ms,
				670ms transform $transition-timing-function 233ms;
		}
		&:nth-child(6) {
			transition: 670ms opacity $transition-timing-function 283ms,
				670ms transform $transition-timing-function 283ms;
		}
		&:nth-child(7) {
			transition: 670ms opacity $transition-timing-function 333ms,
				670ms transform $transition-timing-function 333ms;
		}
		&:nth-child(8) {
			transition: 670ms opacity $transition-timing-function 383ms,
				670ms transform $transition-timing-function 383ms;
		}
		&:nth-child(9) {
			transition: 670ms opacity $transition-timing-function 433ms,
				670ms transform $transition-timing-function 433ms;
		}
		&:nth-child(10) {
			transition: 670ms opacity $transition-timing-function 483ms,
				670ms transform $transition-timing-function 483ms;
		}
		&:nth-child(11) {
			transition: 670ms opacity $transition-timing-function 533ms,
				670ms transform $transition-timing-function 533ms;
		}
		&:nth-child(12) {
			transition: 670ms opacity $transition-timing-function 583ms,
				670ms transform $transition-timing-function 583ms;
		}
		&:nth-child(13) {
			transition: 670ms opacity $transition-timing-function 633ms,
				670ms transform $transition-timing-function 363ms;
		}
		&:nth-child(14) {
			transition: 670ms opacity $transition-timing-function 683ms,
				670ms transform $transition-timing-function 683ms;
		}
	}

	&__link {
		@extend %fs-summary;
		display: inline-block;
		border-bottom: 2px solid transparent;
		color: $black;
		font-weight: bold;
		line-height: $reduced-line-height;

		&:hover,
		&:focus {
			color: $black;
			border-bottom-color: $black;
		}

		&.-isCurrent,
		&.-isSection {
			color: $black;
			border-bottom-color: $black;
		}
	}
}

.headerPopupSearch {
	margin-top: 2rem;

	&__title {
		@extend %fs-summary;
		@include heading;
		margin-bottom: 0.5rem;
		color: $black;
		line-height: $reduced-line-height;

		opacity: 0;
		transform: translateY(30px);
		transition: 670ms opacity $transition-timing-function 133ms,
			670ms transform $transition-timing-function 133ms;
	}

	&__form {
		opacity: 0;
		transform: translateY(30px);
		transition: 670ms opacity $transition-timing-function 183ms,
			670ms transform $transition-timing-function 183ms;
	}

	.searchForm {
		margin-right: -1rem;

		&__input {
			-webkit-appearance: none;
			border: none !important;
			height: 52px;
			font-size: 15px;
		}
		&__button {
			position: relative;
			margin-left: -1rem;
			height: 52px;
			width: auto;
			background-color: $white;
			border: none;
			border-radius: 0;
			color: $black;
		}
		&__buttonIcon {
			font-size: 22px;
		}
	}
}

/*  ==========================================================================
	# NEWS
	======================================================================= */

.block__newsListing {
	margin-bottom: 2rem;
}

.newsCategories {
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 2rem;

	&__heading {
		margin-top: 0;
		color: $brand-text;
	}

	&__item {
		&:not(:last-child) {
			margin-right: 0.5rem;
		}
	}

	&__link {
		@extend %fs-x-small;
		display: block;
		padding: 0.325rem 0.75rem;
		margin-right: 2px;
		background-color: $brand-grey;
		border-bottom: unset;
		color: $black;
		border-radius: 100px;

		&:hover,
		&:focus {
			background-color: $brand-green;
		}

		&.-current {
			background-color: $brand-blue;
			color: $white;
		}
	}
}

.news {
	padding: 2rem 0;

	@include media($ipad) {
		padding: 5rem 0;
	}

	&__image {
		margin-bottom: 2rem;
	}

	&__info {
		padding-bottom: 0.5rem;
		border-bottom: solid 2px $grey;
	}

	&__article {
		@include media($medium) {
			padding-right: 2rem;
		}
	}

	&__wysiwyg {
		color: $white;
		a {
			border-bottom-style: dotted;
			border-bottom-color: $white;
			&:before {
				content: '';
				position: absolute;
				top: -4px;
				left: -4px;
				right: -4px;
				bottom: -4px;
				opacity: 0;
				background-color: $brand-orange;
				z-index: -1;
				transition: all 250ms ease-in-out;
				clip-path: polygon(0 5%, 100% 0, 100% 100%, 0 95%);
			}
			&:hover {
				border-bottom: none;
				&:before {
					opacity: 1;
				}
			}
		}
	}
}

.newsCard,
.news {
	&__image {
		margin-bottom: 1rem;
	}

	&__info {
		@extend %fs-x-small;
		display: flex;
		justify-content: space-between;
		margin-bottom: 0.5rem;
		font-weight: 700;
	}
}

.newsCard {
	display: flex;
	flex-direction: column;
	position: relative;
	height: 100%;
	margin-right: 1rem;
	padding-bottom: 1rem;
	border: unset;

	&__image {
		margin-bottom: 0;

		img {
			display: block;
		}
	}

	&__contentContainer {
		flex-grow: 1;
		padding: 1rem;
		background-image: linear-gradient(
			to right,
			rgba(0, 0, 0, 0.5),
			rgba(0, 0, 0, 0.5)
		);
		background-position: 50% 100%;
		background-size: 0% 6px;
		background-repeat: no-repeat;
		transition: background-size 250ms $transition-timing-function;
	}
	&__holder div:nth-child(5n + 1) &__contentContainer {
		background-color: $brand-green;
	}
	&__holder div:nth-child(5n + 2) &__contentContainer {
		background-color: $brand-blue;
	}
	&__holder div:nth-child(5n + 3) &__contentContainer {
		background-color: $brand-pink;
	}
	&__holder div:nth-child(5n + 4) &__contentContainer {
		background-color: $brand-purple;
	}
	&__holder div:nth-child(5n) &__contentContainer,
	&__holder div:nth-child(5n + 5) &__contentContainer {
		background-color: $brand-orange;
	}

	&__info {
	}
	&__title {
		@extend %fs-summary;
		margin: 0;
		padding-bottom: 0.25rem;
		line-height: 1.2;
	}

	&__content {
		@extend %fs-small;
		max-height: 0;
		overflow: hidden;
		line-height: 1.4;

		transition: max-height 250ms $transition-timing-function;
	}

	@at-root a#{&} {
		color: $black;

		&:hover,
		&:focus {
			.newsCard__contentContainer {
				background-size: 100% 6px;
			}
			.newsCard__content {
				max-height: 300px;
			}
		}
	}
}

.recentNews {
	@include media($large) {
		padding-left: 2rem;
	}

	&__heading {
		margin-top: 0;
		color: $brand-text;
	}

	&__list {
		list-style: none;
		margin-top: 1rem;
	}

	&__link {
		@extend %fs-small;
		position: relative;
		display: block;
		padding: 0.25rem 0;
		border-top: 1px solid transparent;
		padding-left: 2rem;
		background-color: transparent;
		color: $white;
		line-height: 1.1;
		transition: all 250ms ease-in-out;

		&.-selected {
			background-color: $brand-blue;
			color: $white;
		}

		&:hover {
			background-color: $brand-orange;
			color: $black;
		}
		&::before {
			content: '•';
			display: inline-block;
			position: absolute;
			top: 13px;
			left: 0;
			transform: translateY(-50%);
			margin-right: 0.75rem;
			margin-left: 0.25rem;
			font-size: 14px;
			font-family: 'Font Awesome 5 Pro';
			font-weight: 900;
			-moz-osx-font-smoothing: grayscale;
			-webkit-font-smoothing: antialiased;
			font-style: normal;
			font-variant: normal;
			text-rendering: auto;
			line-height: 1;
		}
	}
}

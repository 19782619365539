/*  ==========================================================================
	# Richtext
	======================================================================= */

.panel__wysiwyg {
	.panel__summary + & {
		padding-top: 0;
	}
}

.wysiwyg {
	pointer-events: all;
	color: $white;

	&__title {
		@extend %fs-h1;
		font-weight: bold;
		margin-bottom: 1rem;
	}

	> *:first-child {
		margin-top: 0;
	}

	> *:last-child {
		margin-bottom: 0;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $brand-text;
		margin-top: 0;
	}

	a {
		color: $brand-text-alt;
		border-bottom-color: $brand-text-alt;
		font-weight: 700;

		&:hover,
		&:focus {
			color: $white;
			border-bottom-color: $white;
		}
	}

	blockquote {
		@extend %fs-h5;
		@include heading;
		position: relative;
		margin: 4rem 0;
		padding-top: 3rem;
		padding-bottom: 3rem;
		padding-left: 2rem;
		border-left: solid 10px $brand-dark;
		color: $brand-text;
	}

	ul {
		padding-left: 1.25rem;
	}
	ol {
		padding-left: 0.825rem;

		li {
			padding-left: 1rem;
		}
	}
}

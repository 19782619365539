/*  ==========================================================================
	#Global
	======================================================================= */

html {
	min-width: 320px;
	height: 100%;
	box-sizing: border-box;
	scroll-behavior: smooth;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

body {
	@extend %fs-body;
	position: relative;
	min-height: 100%;
	background-color: #000;
	overflow-x: hidden;
}

.main {
	display: flex;
	flex-direction: column;
	position: relative;
	background: $black;
	z-index: 100;
	overflow: hidden;
}

.panel {
	z-index: 3;
	padding: 4rem 0;
	@include media-max($small) {
		padding: 2rem 0;
	}
}

.dots-holder {
	content: '';
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-image: url('../images/dots.webp');
	z-index: 1;
}

#ball-container {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 2;
	pointer-events: none;
}

.ball {
	position: absolute;
	border-radius: 100%;
	opacity: 0.7;
	z-index: 4;
}

.container {
	position: relative;
	width: 100%;
}

.link {
	@extend a;

	&.-white {
		@include link($white);
	}

	&.-black {
		@include link($black);
	}

	&.-featured {
		@include link($brand-pop);
	}

	&.-underline {
		text-decoration: underline;
	}

	&.-has-border {
		&:hover,
		&:focus {
			padding: 0.5rem;
			border: 1px solid $white;
			border-radius: $button-border-radius;
		}
	}
}

/* =============================================================================
	# Headings
================================================================================ */

.heading {
	@include heading();
	margin: $base-spacing 0 ($base-spacing / 2) 0;
	color: $brand-purple;

	&:first-child {
		margin-top: 0;
	}

	&.-superLarge {
		@extend %fs-hero;
	}

	&.-large,
	&.-pageHeading {
		@extend %fs-h1;
		margin-top: 0;
		margin-bottom: 1rem;
		text-transform: uppercase;
	}

	&.-isCentered {
		text-align: center;
	}
}

.-bold {
	font-weight: 700;
}

/* =============================================================================
	# BG
================================================================================ */

.bg {
	background-color: $white;

	&.-primary {
		background-color: $brand-light;
	}

	&.-secondary {
		background-color: $brand-dark;
	}

	&.-grey {
		background-color: $grey;
	}

	&.-dark-grey {
		background-color: $dark-grey;
	}

	&.-image {
		@include background-image();
	}
}

/*  ==========================================================================
	#Etc
	======================================================================= */

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.hidden {
	display: none;
}

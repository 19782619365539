/*  ==========================================================================
	# HERO BANNER
	======================================================================= */

.panel__heroBanner {
	position: relative;
	overflow: hidden;
	padding: 0;
	z-index: 3;
}

.block__heroBanner {
	position: relative;
	height: 100%;
	padding: 0;
}

.heroBanner {
	position: relative;

	&__slide {
		display: none;

		&:first-child {
			display: block;
		}
	}

	&__contentWrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		padding: 4vw;
		background-color: $brand-purple;
	}

	&__content {
		text-align: center;

		@include media-max($ipad) {
			padding: 1rem;
		}
	}

	&__title {
		@extend %fs-hero;
		@include heading;
		color: #fff;
		line-height: 1.1;
	}

	&__subtitle {
		@extend %fs-h4;
		margin-top: 1rem;
		color: #fff;

		@include media-max($ipad) {
			margin-bottom: 0;
		}
	}

	&__button {
		z-index: 1;
		padding: 1rem;
		text-transform: uppercase;
		font-weight: bold;
		width: 200px;
		height: 200px;
		left: 50%;
		transform: translateX(-50%);
		border-radius: 100px;
		display: flex;
		margin-top: 2rem;
		justify-content: center;
		align-items: center;
		text-align: center;
		background-color: $black;
		color: $white;
		transition: all 250ms ease-in-out;
		font-size: 20px;

		&:hover {
			background-color: $white;
			color: $black;
			background-size: 0;
		}
	}
}

.slick-slider {
	@include media-max($ipad) {
		margin-bottom: 32px;
	}
}

.slick-dots {
	position: absolute;
	bottom: -38px;
	left: 50%;
	transform: translateX(-50%);
	list-style: none;

	@include media($ipad) {
		bottom: 1rem;
	}

	li {
		display: inline-block;
		margin: 0.5rem;
	}

	button {
		display: block;
		width: 24px;
		height: 24px;
		overflow: hidden;
		border: solid 2px $white;
		border-radius: 50%;
		background-color: $white;
		text-indent: -99px;

		@include media-max($ipad) {
			border-color: $brand-pop;
		}
	}

	.slick-active button {
		background-color: $brand-pop;
	}
}

/*  ==========================================================================
	# Cards
	======================================================================= */

.panel__exhibitorCards {
	padding: 4rem 0;
}

.exhibitorCards {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 3rem -1rem 0;
}

.exhibitorCard {
	@include transition(all);
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	position: relative;
	height: 100%;
	margin: 0 1rem;
	line-height: 1.4;
	border-bottom: none;
	@include media-max($small) {
		margin: 0;
	}

	&:hover {
		.exhibitorCard__contentContainer {
			background-size: 100% 6px;
		}
	}

	&:not(a) {
		pointer-events: none;
	}

	&__blockHeading {
		text-align: center;
		margin-bottom: 2rem;
	}

	&__container {
		margin-top: 1.5rem;
		margin-bottom: 1.5rem;
		width: 25%;
		@include media-max($large) {
			margin-top: 1rem;
			margin-bottom: 1rem;
		}
		@include media-max($ipad) {
			width: 50%;
		}
		@include media-max($small) {
			width: 100%;
		}
	}

	&__image {
		width: 100%;
		padding-bottom: 66.67%;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		background-color: transparent;
		display: block;
		transition: all 250ms ease-in-out;
		position: relative;
	}

	&__contentContainer {
		position: relative;
		min-height: 120px;
		height: 100%;
		width: 100%;
		padding: 1rem;
		background-repeat: no-repeat;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__title {
		@extend %fs-h4;
		margin: 0;
		padding-bottom: 0.25rem;
		line-height: 1.1;
	}

	&__title {
		position: relative;
		width: 100%;
		margin-bottom: 1rem;
		font-family: $heading-font-family;
		margin: 0;
		text-align: center;
		color: $black;
	}

	&__container:nth-child(5n + 1) &__contentContainer {
		background-color: $brand-green;
	}
	&__container:nth-child(5n + 2) &__contentContainer {
		background-color: $brand-purple;
	}
	&__container:nth-child(5n + 3) &__contentContainer {
		background-color: $brand-dark;
	}
	&__container:nth-child(5n + 4) &__contentContainer {
		background-color: $brand-pink;
	}
	&__container:nth-child(5n + 5) &__contentContainer {
		background-color: $brand-pink;
	}

	&__content {
		@extend %fs-small;
		max-height: 0;
		overflow: hidden;
		line-height: 1.4;

		transition: max-height 250ms $transition-timing-function;
	}

	&__link {
		@extend %fs-small;
		display: block;
		padding: 1rem 1rem 0.75rem;
		font-weight: bold;
		flex-grow: 1;
		background-color: $grey;
	}

	&__content + &__link {
		flex-grow: 0;
	}

	&:hover {
	}
}

.pageHeader {
	$this: &;
	position: relative;
	width: 100%;
	z-index: 999;

	&__socials {
		@include media-max($menubreak) {
			display: none;
		}
	}
}

.brandContainer {
	&__link {
		display: block;
		border-bottom: none;
	}

	&__image {
		display: block;
		margin: 37.5px 0;
		transform: translateX(-12.5%);
		@include media-max($menubreak) {
			max-width: 140px;
			margin: 17.5px 0 17.5px 17.5px;
		}
	}
}

.socialIcons {
	margin-bottom: 1vw;
	margin-top: -0.5rem; //align with logo

	&__link {
		display: inline-block;
		width: 44px;
		height: 44px;
		border-bottom: none !important;
		text-align: center;
		color: $white;
		font-size: 24px;
		vertical-align: top;
		line-height: 44px;

		@include media-max($menubreak) {
			font-size: 18px;
		}
		&:hover,
		&:focus {
			color: $brand-pop;
			opacity: 1;
		}
	}
}

.skip-to-content {
	position: absolute;
	top: 5%;
	left: 0;
	padding: 0 1rem;
	opacity: 0;
	pointer-events: none;
	z-index: 9999;
	background-color: $brand-pop;
	color: #000;

	@include media-max($ipad) {
		display: none;
	}

	&:focus {
		opacity: 1;
		pointer-events: all;
		color: $black;
		outline: 2px dashed $white;
	}
}

/* Video background */

.videobg {
	position: relative;
	width: 100%; /* Set video container element width here */
	height: 32vw; /* Set video container element height here */
	@include media-max($medium) {
		height: 56.25vw; /* 16:9 ratio */
	}
	overflow: hidden;
	background: #111; /* bg color, if video is not high enough */
	z-index: 3;
	/* horizontally center the video */

	// Small = 3.2:1 = 32vw / max height 600px
	// Med = 2.5:1 = 40vh / max height 768px
	// Large = 16:9 = 56.25vh / max height 1080px

	&,
	&.-small {
		height: 32vw;
		@media screen and (min-width: 1920px) {
			max-height: 600px;
		}
	}

	&.-medium {
		height: 40vw;
		@media screen and (min-width: 1920px) {
			max-height: 768px;
		}
	}

	&.-large {
		height: 56.25vw;
		@media screen and (min-width: 1920px) {
			max-height: 1080px;
		}
	}

	&-width {
		position: absolute;
		width: 100%; /* Change width value to cover more area*/
		height: 100%;
		left: -9999px;
		right: -9999px;
		margin: auto;
	}

	/* set video aspect ratio and vertically center */
	&-aspect {
		position: absolute;
		width: 100%;
		height: 0;
		top: -9999px;
		bottom: -9999px;
		margin: auto;
		padding-bottom: 56.25%; /* 16:9 ratio */
		overflow: hidden;
	}

	&-make-height {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	&-container {
		width: 100%;
		height: 100%;
		overflow: hidden;
		background: #111;
		position: relative;
		z-index: -9;
	}

	iframe {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		border: 0 none;
	}
}

.exhibitorLogin {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 30px;
	transition: all 250ms;
	&:hover {
		.exhibitorLogin__text {
			color: $brand-orange;
		}
		.exhibitorLogin__image {
			filter: invert(51%) sepia(73%) saturate(3337%) hue-rotate(9deg)
				brightness(103%) contrast(99%);
		}
	}
	&__text {
		width: min-content;
		font-size: 12px;
		line-height: 15px;
		margin-left: 7px;
		font-weight: bold;
		letter-spacing: 1px;
		transition: all 150ms;
	}
	&__image {
		height: 27px;
		transition: all 250ms;
	}
}

.headerEvents {
	z-index: 6;
	display: flex;
	width: 100%;
	justify-content: center;
	transition: all 250ms ease-in-out;
	gap: 4px;
	margin-bottom: 4px;
	@include media-max($ipad) {
		flex-direction: column;
		gap: 2px;
	}
	&__item {
		padding: 0;
		display: flex;
		flex-direction: column;
		max-width: 25%;
		width: 25%;
		border-radius: 0;
		background-color: transparent;
		&:hover {
			background-color: transparent;
		}

		@include media-max($ipad) {
			max-width: 100%;
			width: 100%;
		}

		&.-isSection {
			.headerEvents__button {
				background-color: $brand-pop !important;
			}
			.headerEvents__link {
				color: #000;
			}
			@include media($ipad) {
				&::before {
					content: '';
					position: absolute;
					top: 50%;
					left: 0;
					width: 100%;
					height: calc(50% + 4px);
					background-color: $brand-pop;
				}
			}
		}

		&:nth-child(1) {
			.headerEvents__button {
				background-color: $brand-dark;
				clip-path: polygon(0 5%, 100% 0, 100% 100%, 0 95%);
			}
			.subNav {
				background: $brand-grey;
			}
		}
		&:nth-child(2) {
			.headerEvents__button {
				background-color: $brand-green;
				clip-path: polygon(0 5%, 100% 0, 100% 100%, 0 95%);
			}

			.subNav {
				background: $brand-grey;
			}
		}
		&:nth-child(3) {
			.headerEvents__button {
				background-color: $brand-pink;
				clip-path: polygon(0 5%, 100% 0, 100% 100%, 0 95%);
			}
			.subNav {
				background: $brand-grey;
			}
		}
		&:nth-child(4) {
			.headerEvents__button {
				background-color: $brand-purple;
				clip-path: polygon(0 5%, 100% 0, 100% 100%, 0 95%);
			}
			.subNav {
				background: $brand-grey;
			}
		}
		.subNav {
			width: 100%;
			overflow: auto;
			border-top: none;
			flex-direction: column;
			z-index: 0;
			&__item {
				display: flex;
				justify-content: center;
			}
			&__link {
				color: $black;
				white-space: nowrap;

				&::before {
					display: none;
				}
				&:focus {
					outline: 2px dashed $black;
					clip-path: none;
				}
				&.-isCurrent,
				&.-isSection {
					background-color: $brand-pop;
					color: $black;
				}
			}
			@include media($menubreak) {
				display: none;
			}
		}
	}

	@include media-max($ipad) {
		&__button {
			clip-path: none !important;
		}
	}

	&__button {
		&:focus {
			clip-path: none !important;
			outline: 2px dashed $white;
		}
	}

	&__tickets {
		position: absolute;
		right: 50%;
		top: 0;
		transform: translate(50%, -90%) rotate(15deg);
		z-index: 3;
		pointer-events: none;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		height: 72px;
		width: 72px;
		border-radius: 72px;
		background-color: white;
		font-size: 13px;
		font-weight: 700;
		color: #2d3c4b;
		text-transform: uppercase;
		scale: 0.75;
		border: 1px solid $black;

		@include media-max($ipad) {
			top: 50%;
			transform: translateY(-50%) rotate(15deg);
			right: 5px;
			scale: 1;
			height: 60px;
			width: 60px;
			font-size: 10px;
		}
		@include media-max($small) {
		}
	}

	&__link {
		padding: 12px 16px 10px;
		text-align: center;
		color: $white;
		width: 100%;
		@include media-max($ipad) {
			max-width: unset;
			padding: 6px 6px 3px;
		}
		border-bottom: none;
		position: relative;
		border: 2px solid transparent;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100%;
	}
	&__title {
		font-family: $heading-font-family;
		font-size: 34px;
		line-height: 39px;
		font-weight: 400;
		margin: 0;
		text-transform: uppercase;
		display: inline-flex;
		align-items: center;
		letter-spacing: 0;
		@include media-max($ipad) {
			font-size: 18px;
			line-height: 1;
			margin-bottom: 6px;
		}
		.fa-angle-down {
			margin-left: 5px;
			transition: all 250ms ease-in-out;
			@include media-max($ipad) {
				font-size: 16px;
			}
			@include media($menubreak) {
				display: none;
			}
			&.-rotated {
				transform: rotate(180deg);
			}
		}
	}

	&__date {
		margin: 0;
		font-size: 14px;
		line-height: 18px;
		font-weight: 400;
		@include media-max($ipad) {
			margin-top: -5px;
		}
	}
	svg {
		position: absolute;
		top: calc(100% - 1px);
		left: 0;
		right: 0;
		width: 100%;
		stroke: $brand-dark;
		display: none;
	}
}

/*  ==========================================================================
	# Colors
	======================================================================= */

// Basic
$white: #fff;
$black: #000;

// Project colours
$brand-light: #000;
$brand-dark: #127ec2;
$brand-pop: #ef7d01;

$brand-darkpink: $brand-light;

$brand-orange: $brand-pop;
$brand-blue: $brand-dark;
$brand-green: #3da735;
$brand-red: #db1b17;
$brand-grey: #e2e4e5;
$brand-purple: #9e2f88;
$brand-pink: #e62385;

$brand-text: $white;
$brand-text-alt: #7a8972;

// Greys
$gray: $brand-grey;
$grey: $gray;

$mid-gray: #555;
$mid-grey: $mid-gray;

$dark-gray: #222;
$dark-grey: $dark-gray;

//Red
$red: #ec6543;

// UI
$error: #ec6543;
$warning: #ec6543;

// Define colour map
$colors: (
	white: $white,
	black: $black,
	grey: $grey,
	mid-grey: $mid-grey,
	dark-grey: $dark-grey,
	brand-light: $brand-light,
	brand-dark: $brand-dark,
	brand-pop: $brand-pop,
	red: $red,
	hotpink: hotpink,
);

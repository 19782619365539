/**
 * Include all the block styles for Craft Matrix / Neo fields.
 */

.panel {
	&.-heading {
		padding-bottom: 0;
	}

	&.-no-overflow {
		overflow: hidden;
	}

	&__summary + &__button,
	&__wysiwyg + &__button {
		margin-top: -3em;
	}

	&__wysiwyg,
	&__imageTextColumn,
	&__summary {
		a:not(.button) {
			border-bottom: solid 1px $white;
			color: $white;

			&:focus,
			&:hover {
				border-bottom-color: transparent;
			}
		}
	}

	&__spacer {
		&.-small {
			height: 5vh;
		}

		&.-large {
			height: 15vh;
		}
	}
}

.block {
	&__summary {
		text-align: center;
		margin-top: 0;
	}

	&.-center {
		text-align: center;
	}
}

.-bg-white {
	background-color: $white;
}

.-bg-black {
	background-color: transparent;
}

@import 'blocks/banner';
@import 'blocks/brands';
@import 'blocks/cards';
@import 'blocks/events';
@import 'blocks/gallery';
@import 'blocks/hero-banner';
@import 'blocks/image-text-column';
@import 'blocks/image';
@import 'blocks/instagramFeed';
@import 'blocks/news';
@import 'blocks/video';
@import 'blocks/wysiwyg';
@import 'blocks/slideshow';

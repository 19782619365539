.footer {
	position: relative;
	z-index: 101;
	padding-bottom: 5.5rem;
	padding-top: 0;
}

.footerTop {
	padding: 3rem 0;

	p {
		margin-bottom: 0.5rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__content {
		@include media-max($medium) {
			order: -1;
		}
		color: $white;
		a {
			@include link($white, $white);

			&:hover,
			&:active,
			&:focus {
				border-bottom-color: $white;
				color: $white;
			}
		}
	}

	&__socials {
		display: none;
		@include media-max($menubreak) {
			display: block;
		}
	}
}

.footerBottom {
	@extend %fs-small;
	padding: 3rem 0 3rem;
	background-color: $brand-grey;
	@include media($medium) {
		padding: 1.5rem 0;
	}

	.footerInfo {
		display: flex;
		align-items: center;
		color: $black;
	}
}

.footerNav {
	display: inline-block;

	@include media($medium) {
		margin-right: 1rem;
	}

	&__list {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}

	&__item {
		display: inline-block;
		margin: 0 0.5rem 0 0;
		padding: 0;

		&:not(:first-child)::before {
			content: '|';
			margin-right: 0.5rem;
			color: $black;
		}
	}

	&__link {
		color: $black;
		padding: 0.3rem;
		transition: all 250ms ease-in-out;

		&:hover,
		&:focus {
			background-color: $white;
			color: $black;
			clip-path: polygon(0 5%, 100% 0, 100% 100%, 0 95%);
		}
	}
}

.footerSupplementaryContent {
	margin: 2rem 0;
	color: $white;
	@extend %fs-xx-small;
	line-height: 1.6;

	p:last-child {
		margin-bottom: 0;
	}
}

.plastykLogo {
	margin: 2rem 0;
	font-family: Arial, sans-serif;

	.footerSupplementaryContent + & {
		@include media-max($small) {
			margin-top: 0;
		}
	}

	&__text {
		letter-spacing: -0.5px;
	}

	&__link {
		@include link($white);
		@include flex-layout(flex-start, flex-end);
		font-size: 11px;
		line-height: 13px;

		@include media($small) {
			float: right;
		}
	}

	&__img {
		margin: 0 3px;
		max-width: 42px;
	}
}

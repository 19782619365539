$form-active: $brand-dark;
$form-disabled: lighten($grey, 10);
$form-border-color: rgba($dark-grey, 0.9);
$form-field-border: 2px solid $form-border-color;

.fui {
	&-i {
		font-family: $base-font-family;
	}

	&-form {
		@extend %fs-body;
		padding: 1rem 0;
		text-align: left;
	}

	&-field {
		position: relative;
	}

	&-label,
	&-legend,
	&-input,
	&-select,
	&-checkbox,
	&-radio,
	&-alert,
	&-error-message,
	&-btn {
		font-size: inherit !important;
	}

	&-error {
		label {
			color: unset !important;
		}
	}

	&-required {
		color: unset !important;
	}

	&-input,
	&-select {
		border-color: $form-border-color;
		border-radius: $base-border-radius !important;
		font-family: $base-font-family;
	}

	select,
	input[type='text'],
	input[type='email'],
	input[type='password'],
	input[type='tel'],
	input[type='date'],
	input[type='time'],
	input[type='number'],
	input[type='url'],
	textarea {
		@include flex-layout();
		@include transition(all);
		position: relative;
		width: 100%;
		padding: 0.65rem 0.5rem;
		background-color: $white;
		border: solid 1px $form-border-color;
		font-family: $base-font-family;
		line-height: $reduced-line-height;

		&:hover:not(:disabled) {
			cursor: pointer;
		}

		&:focus {
			outline: 0;
		}

		&[disabled] {
			border-color: $form-disabled;
			background-color: $form-disabled;
			cursor: auto;
		}

		&.datepicker {
			padding-right: 2rem;
		}
	}

	/*
	   OVERRIDE FORMIE STYLES.
	   NOT ABLE TO PLACE FORMIE BEFORE THIS FILE
	   SO !important IS NECESSARY
	*/
	&-checkbox input:checked + label::before,
	&-radio input:checked + label::before {
		background-color: $form-active !important;
		border-color: $form-active !important;
	}

	&-checkbox input:checked + label::before {
		background-size: 12px auto !important;
	}

	&-checkbox label::before,
	&-radio label::before {
		top: 0 !important;
		width: 24px !important;
		height: 24px !important; /* set to square, divisible pixels */
	}

	&-type-date-time {
		position: relative;
		cursor: pointer;

		& input::before {
			content: '\f073';
			position: absolute;
			top: 50%;
			right: 0;
			width: 34px;
			height: 44px;
			transform: translateY(-50%);
			pointer-events: none;
			z-index: 1;
			font-family: 'Font Awesome 5 Pro';
			font-weight: 300;
			-moz-osx-font-smoothing: grayscale;
			-webkit-font-smoothing: antialiased;
			font-style: normal;
			font-variant: normal;
			text-rendering: auto;
			line-height: 44px;
		}
	}

	&-instructions,
	&-instructions p {
		@extend %fs-xx-small;
		font-style: italic;
		margin-top: 0 !important;
		margin-right: 0;
		margin-bottom: 0 !important;
		margin-left: 0;
	}

	&-repeater-add-btn:after,
	&-repeater-remove-btn:after {
		background-image: none !important;
		content: '\f067' !important;
		font-family: 'Font Awesome 5 Pro';
		font-weight: 300;
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
		font-style: normal;
		font-variant: normal;
		text-rendering: auto;
		line-height: 1;
	}
}

textarea {
	resize: vertical;
}

.searchForm {
	&__input {
		padding: 1rem !important;
		border-color: $white !important;
		color: $black;
		cursor: text !important;
	}

	&__button {
		width: calc(100% - 1rem);
		margin-left: 1rem;
		padding: 1rem !important;
		background-color: $brand-light;
		border: none;
	}

	&__buttonIcon {
		font-size: 24px;
	}
}

/* NEWS SEARCH FORM */

.newsSearch {
	margin-bottom: 1rem;

	&__form {
		position: relative;
	}

	&__input {
		position: relative;
		width: calc(100% - 2.5rem);
		padding: 0.75rem 3.5rem 0.75rem 1rem;
		background-color: $white;
		border: solid 1px $form-border-color;
		font-family: $base-font-family;
		line-height: $reduced-line-height;
	}

	&__button {
		position: absolute;
		top: 0;
		right: 0;
		width: 5rem;
		margin-left: -3rem;
		line-height: 1.3;
		background-color: $brand-purple;
		color: $white;
		font-size: 18px;
	}
}

body.mobile-menu-open {
	overflow: hidden;
}
@-webkit-keyframes bounceInUp {
	0% {
		opacity: 0;
		top: 100%;
	}
	60% {
		opacity: 1;
		top: -50px;
	}
	75% {
		top: -32px;
	}
	90% {
		top: -40px;
	}
	100% {
		top: -40px;
	}
}

@keyframes bounceInUp {
	0% {
		opacity: 0;
		top: 100%;
	}
	60% {
		opacity: 1;
		top: -50px;
	}
	75% {
		top: -32px;
	}
	90% {
		top: -40px;
	}
	100% {
		top: -40px;
	}
}

.mobile-app-bar {
	display: none;
	position: fixed;
	bottom: 0;
	width: 100%;
	padding: 0;
	background: $brand-pop;
	box-shadow: $box-shadow;
	z-index: 99999;

	@include media-max($menubreak) {
		display: block;
	}

	&__button {
		position: absolute;
		top: 100%;
		left: 50%;
		width: 80px;
		height: 80px;
		margin-left: -40px;
		padding: 8px !important;
		background: $black;
		border: 2px solid $white;
		border-radius: 50%;
		text-align: center;
		color: $white;
		text-transform: uppercase;
		cursor: pointer;
		animation-name: bounceInUp;
		animation-duration: 750ms;
		animation-delay: 1s;
		animation-fill-mode: forwards;
		animation-iteration-count: 1;
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

		&:hover {
			color: $white;
		}
	}

	&__hamburger {
		display: block;
		position: relative;
		width: 30px;
		height: 15px;
		margin: 12px 15px 8px 15px;
		cursor: pointer;

		span {
			display: block;
			position: absolute;
			left: 0;
			width: 100%;
			height: 2px;
			background: $white;
			transform: rotate(0deg);
			transition: all 250ms $transition-timing-function;
			border-radius: 3px;
			opacity: 1;
		}

		span:nth-child(1) {
			top: 0;
		}
		span:nth-child(2),
		span:nth-child(3) {
			top: 6px;
		}
		span:nth-child(4) {
			top: 12px;
		}

		&.is-active span:nth-child(1),
		&.is-active span:nth-child(4) {
			top: 6px;
			left: 50%;
			width: 0%;
		}

		&.is-active span:nth-child(2) {
			transform: rotate(45deg);
		}

		&.is-active span:nth-child(3) {
			transform: rotate(-45deg);
		}
	}

	&__button-text {
		display: block;
		font-size: 12px;
	}

	&__list {
		@include flex-layout(space-around, center);
		height: 72px;
		border-top: 2px solid $white;
	}

	&__item {
		list-style: none;
	}

	&__link {
		@include flex-layout(flex-end, center);
		flex-direction: column;
		height: 47px;
		color: $black;

		&:hover,
		&:focus {
			color: $black;
		}
	}

	&__icon {
		font-size: 24px;
	}

	&__link-text {
		margin-top: 3px;
		font-size: 12px;
		text-transform: uppercase;
	}
}

.mobile-menu {
	@include transition(height, 500ms, $transition-timing-function);
	height: 0;
	background: $white;

	&.active {
		@include transition(height, 500ms, $transition-timing-function);
		height: calc(100vh - 200px);
		overflow: auto;
		-webkit-overflow-scrolling: touch;
	}

	.mobileShare {
		@extend %fs-summary;
		display: none;
		padding: 2px 5px 2px 15px;
		background-color: lighten($brand-light, 10%);
		color: $black;
		align-items: center;
		justify-content: space-between;

		&__icons {
			> a {
				padding: 0 0.5rem;
				font-size: 30px;
				color: $black;

				&:hover,
				&:focus {
					color: $white;
					filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.3));
				}
			}
		}
	}
}

.mobile-menu__nav {
	position: relative;

	> ul {
		width: 100%;
		margin: 0;
		padding: 0;
		text-align: left;
		list-style: none;

		> li {
			display: block;
			padding: 0;
			border-bottom: 1px solid #d8d8d8;

			> ul {
				display: none;
			}

			> a {
				display: block;
				position: relative;
				padding: 15px 0 15px 15px;
				border: none;
				color: #000;
				text-decoration: none;
			}
		}

		> li.backLink > a {
			background: darken($grey, 10);
			padding-left: 40px;
			text-transform: uppercase;
		}

		> li.backLink > a::after,
		> li.has-menu > a::after {
			content: '\f054';
			position: absolute;
			top: 50%;
			margin-top: -7px;
			font-size: 14px;
			line-height: 1;
			font-family: 'Font Awesome 5 Pro';
			speak: none;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
		}

		> li.backLink > a::after {
			content: '\f053';
			left: 15px;
		}
	}

	ul > li.has-menu > a::after {
		right: 15px;
	}

	a.-isCurrent,
	a.-isSection {
		background: $grey;
	}

	> ul > li.mobile-menu__heading a {
		padding-left: 15px;
		background-color: $grey;
		color: #000;
		text-transform: uppercase;
	}
}

.imageTextColumn {
	&__text {
		position: relative;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: flex-start;
		background-color: $brand-red;
		padding: 1rem 1rem 2rem;

		@include media($medium) {
			padding: 2.5rem 2rem 3.5rem;
			margin-top: 1rem;
			margin-bottom: 1rem;
		}
		@include media($ipad) {
			padding: 3rem;
		}

		@include media-max($medium) {
			top: -4vw;
			margin-left: 4vw;
			flex-basis: calc(100% - 8vw);

			h2,
			h3 {
				margin-top: 1rem;
			}
		}

		&.-imageleft {
			@include media($medium) {
				left: -4vw;
			}
		}
		&.-imageright {
			@include media($medium) {
				right: -4vw;
			}
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		p,
		a:not(.button) {
			color: $white;
		}
	}

	&__image {
		position: relative;
		align-self: stretch;
		overflow: hidden;

		> .lazy--background {
			border-radius: 1000px;
			height: 0;
		}
		@include media-max($medium) {
			order: -1 !important;
		}
	}

	&__title {
		@extend %fs-h3;
	}

	&__button {
		margin-top: 2vw;
		background-color: $black;
		color: $white;
	}
}

/*  ==========================================================================
	# BANNER
	======================================================================= */

.panel__banner {
	position: relative;
	overflow: hidden;

	&.-bg-image {
		z-index: 11;
	}
}

.block__banner {
	position: relative;
	height: 100%;
	padding: 0;
}

.banner {
	z-index: 1;
	position: relative;
	height: 100%;

	&.-content {
		display: flex;
		flex-direction: column;
		justify-content: center;

		.-small & {
			padding: 4vw 0;
		}

		.-large & {
			padding: 7vw 0;
		}
	}

	.-bg-image &.-content {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	&__contentWrapper {
		position: relative;
		z-index: 1;
		width: 100%;
	}

	&__content {
		position: relative;
		text-align: center;

		@include media-max($ipad) {
			padding: 1rem;
		}
	}

	&__title {
		@extend %fs-h4;
		@include heading();
		color: $black;
	}
	.-bg-white &__title {
		color: $brand-text;
	}

	&__subtitle {
		@extend %fs-summary;
		margin-top: 1rem;
		color: $black;

		@include media-max($ipad) {
			margin-bottom: 0;
		}
	}

	&__button {
		margin-top: 2.5vw;

		@include media-max($small) {
			width: 100%;
		}
	}
}

/*  ==========================================================================
	# Cards
	======================================================================= */

.panel__cards {
	padding: 4rem 0;
}

.cards {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.card {
	@include transition(all);
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	position: relative;
	height: 100%;
	margin: 0 1rem;
	border: unset;
	line-height: 1.4;

	&__container {
		margin-top: 1rem;
		margin-bottom: 1rem;
	}

	&__image {
		width: 100%;
		padding-bottom: 100%;
		background-size: cover;
		border-radius: 1000px;
		display: block;

		img {
		}
	}

	&__contentContainer {
		position: relative;
		top: -0.75rem;
		height: 100%;
		width: 100%;
		padding: 1.5rem;
		background-color: $brand-dark;
		background-image: linear-gradient(
			to right,
			rgba(0, 0, 0, 0.5),
			rgba(0, 0, 0, 0.5)
		);
		background-position: 50% 100%;
		background-size: 0% 6px;
		background-repeat: no-repeat;
		transition: background-color 350ms $transition-timing-function;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__container:nth-child(even) &__contentContainer {
		top: auto;
		bottom: 0.75rem;
	}

	&__container:nth-child(6n + 1) &__contentContainer {
		background-color: $brand-dark;
	}
	&__container:nth-child(6n + 2) &__contentContainer {
		background-color: $brand-green;
	}
	&__container:nth-child(6n + 3) &__contentContainer {
		background-color: $brand-pink;
	}
	&__container:nth-child(6n + 4) &__contentContainer {
		background-color: $brand-purple;
	}
	&__container:nth-child(6n + 5) &__contentContainer {
		background-color: $brand-pop;
	}
	&__container:nth-child(6n) &__contentContainer,
	&__container:nth-child(6n + 6) &__contentContainer {
		background-color: $brand-pink;
	}

	&__title {
		@extend %fs-h4;
		position: relative;
		width: 100%;
		padding-bottom: 0.5rem;
		margin-bottom: 1rem;
		font-family: $heading-font-family;
		margin: 0;
		text-align: center;
	}

	&__subtitle {
		@extend %fs-x-small;
		margin-bottom: 0.5rem;
		text-transform: uppercase;
		font-weight: bold;
		letter-spacing: 1px;
	}

	&__link {
		@extend %fs-small;
		display: block;
		padding: 1rem 1rem 0.75rem;
		font-weight: bold;
		flex-grow: 1;
		background-color: $grey;
	}

	&__content {
	}

	&__content + &__link {
		flex-grow: 0;
	}

	@at-root a#{&} {
		color: $black;

		&:hover,
		&:focus {
			.card__contentContainer {
				background-color: $white;
			}
		}

		&.-hover-for-white {
			&:hover,
			&:focus {
				.card__contentContainer {
					background-color: $black;
					color: $white;
				}
			}
		}
	}
}

/*  ==========================================================================
	# Instagram Feed
	======================================================================= */

.panel__instagramFeed {
	z-index: 11;
}

.instagramFeed {
	&__title {
		padding: 1.5rem 0;
		text-align: center;
		.heading {
			margin-bottom: 0;
			color: $brand-purple;
		}
		@include media-max($small) {
			padding-bottom: 0.75rem;
		}
	}

	&__subtitle {
		color: $white;
	}

	&__button {
		color: $white;
		@include media-max($medium) {
			margin-top: 1rem;
			margin-bottom: 1rem;
		}
		@include media-max($small) {
			text-align: left;
			padding-left: 0 !important;
			margin-top: 0;
		}

		&:hover,
		&:active,
		&:focus {
			color: $brand-pink;
		}
	}

	&__images {
		display: flex;

		@include media-max($medium) {
			flex-wrap: wrap;
		}
	}

	&__link {
		border-bottom: none;

		@include media-max($medium) {
			width: 50%;
		}
	}

	&__image {
		display: block;
		width: 100%;
	}

	&__mediaIcon {
		height: 22px;
		width: 22px;
		position: absolute;
		top: 10px;
		right: 10px;
		display: block;
		fill: $white;
		filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.4));
	}
}

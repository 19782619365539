@import 'photoswipe/dist/photoswipe';
$pswp__assets-path: '../images/';
@import 'photoswipe/src/css/default-skin/default-skin';

/*  ==========================================================================
	# Gallery
	======================================================================= */

.gallery {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 0 -0.5rem;

	&__heading {
		margin-bottom: 2rem;
		color: $brand-green;
	}

	&__item {
		display: flex;
		align-items: center;
		position: relative;
		width: 20%;
		overflow: hidden;
		line-height: 1;
		padding: 0.5rem;
		background: #fff;
		margin: 0 0.5rem 0.5rem;
		border-radius: 300px;
		// &:first-child {
		// 	margin-left: 0;
		// }
		// &:last-child {
		// 	margin-right: 0;
		// }

		&.-small {
			width: 25%;
			padding-bottom: 25%;

			@include media($medium) {
				width: 12.5%;
				padding-bottom: 12.5%;
			}
		}

		&.-large {
			width: 50%;
			padding-bottom: 50%;

			@include media($medium) {
				width: 20%;
				padding-bottom: 20%;
			}
		}

		> a {
			@include transition(all);
			position: relative;

			&::after {
				@include transition(opacity, top);
				opacity: 0;
				content: '\f00e';
				display: inline-block;
				position: absolute;
				top: 100%;
				left: 50%;
				width: 44px;
				height: 44px;
				transform: translate(-50%, -50%);
				color: $white;
				font-size: 44px;
				font-family: 'Font Awesome 5 Pro';
				font-weight: 300;
				-moz-osx-font-smoothing: grayscale;
				-webkit-font-smoothing: antialiased;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				line-height: 1;
			}
		}

		&:hover,
		&:focus {
			> a::after {
				opacity: 1;
				top: 50%;
			}
		}

		a {
			display: block;
			margin: 0 !important;
			padding: 0 !important;
			background: none !important;
			border: none !important;
		}

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: block;
			border-radius: 400px;
			object-fit: contain;
		}
	}
}

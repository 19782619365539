.panel__image {
	padding: 5vw 0;
}

.imageBlock {
	&__container {
		position: relative;
		max-width: 1440px;
		margin: 0 auto;

		&.-padded {
			margin: 30px 0;
		}

		img.imageBlock__video {
			position: relative;
			width: 100%;
			height: auto;
		}
	}

	&__title {
		@extend %fs-h2;
		text-align: center;
		margin-top: 0.5em;
		margin-bottom: 0.3em;
		color: $brand-green;
	}

	&__sizer {
		max-width: 910.24px;
	}
}

.modal {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba($black, 0.5);
	z-index: 9999;
	opacity: 0;
	visibility: hidden;
	transform: scale(1.1);
	transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
	&__content {
		display: flex;
		flex-direction: column;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: $white;
		padding: 46px;
		max-width: 95%;
		width: 50rem;
		max-height: 95%;

		color: #000;
		overflow-y: auto;
		@include media-max($ipad) {
			max-height: 85%;
			top: 45%;
		}
		@include media-max($small) {
			padding: 56px 10px 20px;
		}
	}

	&__imageContainer {
		position: relative;
		height: 0;
		padding-bottom: 66.66666%;
		left: 0;
		top: 0;
		width: 100%;
		.modal__image {
			background-color: $black;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			height: 100%;
			width: 100%;
			object-fit: contain;
		}
	}
	&__title {
		font-weight: 400;
		padding: 5px 0;
		margin-top: -10px;
		font-family: $heading-font-family;
		text-align: left;
		font-size: 32px;
		line-height: 33px;
		margin-bottom: 20px;
		margin-top: 40px;
	}

	&__description {
		font-size: 16px;
		line-height: 24px;
	}

	&__socials {
		display: flex;
		justify-content: left;
		margin-top: 26px;
		.socialIcons__link {
			text-align: left;
			font-size: 34px;
			width: 60px;
			color: #000;
		}
	}

	&__close {
		position: absolute;
		top: 0px;
		right: 0px;
		text-align: center;
		cursor: pointer;
		width: 60px;
		height: 60px;
		font-family: 'Arial';
		font-size: 24px;
		line-height: 0.8;
		color: $black;
		padding: 0.5rem;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: all 250ms ease-in-out;
		z-index: 2;
	}
	&__trigger {
		cursor: pointer;
	}
}
.show-modal {
	opacity: 1;
	visibility: visible;
	transform: scale(1);
	transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}

// inner wrapper for sites with full-width coloured sections
.pageInner {
	margin: 0 auto;
	padding: 0 2vw;
	max-width: 1380px;

	@include media-max($medium) {
		padding: 0 4vw;
	}
}

/* ----- Responsive videos ----- */
.embed-container,
.video-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
	margin: 0 0 $small-spacing;
	font-size: 16px;

	iframe,
	object,
	embed,
	.google-map {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

/* ----- No / Only ----- */
.-noPrint {
	@media print {
		display: none !important;
	}
}

.-onlyPrint {
	@media screen {
		display: none !important;
	}
}

.-noWrap {
	white-space: nowrap;
}

.-isHidden {
	display: none !important;
}

/* ----- Accessibility ----- */
/**
* Visually hide an element, but leave it available for screen readers
* @link https://github.com/h5bp/html5-boilerplate/blob/master/dist/css/main.css
* @link http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
*/
.screen-reader {
	position: absolute;
	width: 1px;
	height: 1px;
	overflow: hidden;
	margin: -1px;
	padding: 0;
	border: 0;
	clip: rect(0 0 0 0);
}

/**
* Extends the .screen-reader class to allow the element to be focusable when navigated to via the keyboard
* @link https://github.com/h5bp/html5-boilerplate/blob/master/dist/css/main.css
* @link https://www.drupal.org/node/897638
*/
.screen-reader-focusable:active,
.screen-reader-focusable:focus {
	position: static;
	width: auto;
	height: auto;
	overflow: visible;
	margin: 0;
	clip: auto;
}

.-sticky-top {
	position: sticky;
	top: 0;
}

[data-buttonexplode-outer] {
	overflow: hidden;
	position: relative;
	display: inline-block;
	height: 100%;
	width: 100%;
}

[data-buttonexplode-circle] {
	background-color: rgba($color: #000000, $alpha: 0.2);
	border-radius: 1000px;
	position: absolute;
	left: 0;
	top: 0;
	width: 0px;
	height: 0px;
	margin-left: 0px;
	margin-top: 0px;
	pointer-events: none;
	z-index: 2;
	/*animation-timing-function: ease-in-out; */
	&.-explode {
		animation: explode 0.5s forwards;
	}

	&.-desplode {
		animation: desplode 0.5s forwards;
	}
}

[data-buttonexplode-inner] {
	display: inline-block;
}

@keyframes explode {
	0% {
		width: 0px;
		height: 0px;
		margin-left: 0px;
		margin-top: 0px;
		background-color: rgba($color: #000000, $alpha: 0.2);
	}
	100% {
		width: 2000px;
		height: 2000px;
		margin-left: -1000px;
		margin-top: -1000px;
		background-color: rgba($color: #000000, $alpha: 0.2);
	}
}

@keyframes desplode {
	0% {
		width: 2000px;
		height: 2000px;
		margin-left: -1000px;
		margin-top: -1000px;
		background-color: rgba($color: #000000, $alpha: 0.2);
	}
	100% {
		width: 0px;
		height: 0px;
		margin-left: 0px;
		margin-top: 0px;
		background-color: rgba($color: #000000, $alpha: 0.2);
	}
}

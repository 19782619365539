.searchPage.panel {
	padding-top: 0;
}

.searchResults {
	margin-top: 3rem;

	&__list {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	&__item {
		margin-bottom: 3rem;
		padding-bottom: 3rem;
		border-bottom: solid 1px $grey;

		*:last-child {
			margin-bottom: 0;
		}
	}

	&__link {
		margin-top: 1rem;
		display: block;
	}

	&__summary {
		@extend %fs-small;
	}
}

.searchPage__form {
	margin-bottom: 3rem;
}

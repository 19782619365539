.exhibitor {
	&__topBar {
		background-color: $brand-pop;
		padding: 0.8rem 2rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		z-index: -2;
	}
	&__heading {
		color: $black;
		font-size: 31px;
		line-height: 33px;
		margin-top: 0;
		margin-bottom: 1.5rem;
	}
	&__form {
		display: flex;
		justify-content: center;
		align-items: center;
		@include media-max($medium) {
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
		}
	}
	&__filterContainer {
		z-index: 1;
		display: block;
		width: 100%;
		justify-content: center;
	}
	&__filterLabel {
		color: $black;
		font-size: 18px;
		line-height: 30px;
		font-weight: bold;
		margin-right: 1rem;
	}
	&__filterDropdown {
		height: 46px;
		width: 30%;
		padding: 0 0.5rem;
		border: 0;
		color: $black;
		border-right: 8px solid white;
		margin-right: 16px;
		font-family: $base-font-family;
		font-weight: bold;
		font-size: 16px;
		@include media-max($medium) {
			width: 100%;
		}
	}
	&__filterButton {
		background-color: $black;
		color: $white;
		height: 46px;
		padding: 12px 12px;
		background-image: none;
		overflow: hidden;
		position: relative;
		border-radius: 0;
		span {
			position: relative;
			z-index: 2;
			transition: color $base-duration $base-timing;
			font-weight: normal;
			font-size: 16px;
			letter-spacing: 0.1px;
		}
		&:before {
			content: '';
			position: absolute;
			top: 100%;
			left: 0;
			right: 0;
			bottom: 0;
			height: 100%;
			width: 100%;
			background: $white;
			transition: top $base-duration $base-timing;
		}
		&:hover {
			background-color: $black;
			&::before {
				top: 0;
			}
			span {
				color: $black;
			}
		}
		@include media-max($medium) {
			margin-left: 0;
			margin-top: 1.5rem;
		}
	}
}
